type CopySections = {
  HEADLINE: string;
};

export const FEATURED_COPY: CopySections = {
  HEADLINE: 'add drag-and-drop effects, animations, and physics',
};
export const TEMPLATE_COPY: CopySections = {
  HEADLINE: 'customize templates for links pages, announcements, and more',
};
export const ECLECTIC_COPY: CopySections = {
  HEADLINE: 'create experiential websites with unique interactivity',
};
