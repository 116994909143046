import { AspectRatio, List, ListItem, ListItemProps } from '@chakra-ui/react';
import { Box } from '@playful/design_system';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { CTA, CarouselAsset, CarouselItem } from './CTACarousel';
import { HeaderText } from './homePageComponents';

export const CTACarouselDesktop = ({ items }: { items: CarouselItem[] }) => {
  const [previouslyActiveIndex, setPreviouslyActiveIndex] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const carouselWrapperRef = useRef<HTMLUListElement>(null);
  const timeoutRef = useRef<number>();

  const handlePointerEventSetIndex = (index: number) => (event: React.PointerEvent) => {
    const element = event.target as HTMLElement;
    handleSetIndex(index, element);
  };

  const handleSetIndex = useCallback(
    (index: number, element: HTMLElement) => {
      if (element) {
        const listElScroller = element.parentElement?.parentElement as HTMLDivElement;
        if (listElScroller) {
          const scrollToPoint =
            element.offsetWidth / 2 + element.offsetLeft - listElScroller.offsetWidth / 2;
          listElScroller.scrollTo({ left: scrollToPoint, behavior: 'smooth' });
        }
      }
      setPreviouslyActiveIndex(activeIndex);
      setActiveIndex(index);
    },
    [activeIndex],
  );

  const handleAutoAdvance = useCallback(() => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(() => {
      if (!carouselWrapperRef.current) return;
      let newIndex = activeIndex + 1;
      if (newIndex >= items.length) {
        newIndex = 0;
      }
      const element = carouselWrapperRef.current.children[newIndex] as HTMLElement;
      handleSetIndex(newIndex, element);
    }, 5000);
  }, [activeIndex, handleSetIndex, items.length]);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  useEffect(() => {
    handleAutoAdvance();
  }, [activeIndex, handleAutoAdvance]);

  return (
    <>
      <Box
        id={'cta-list'}
        display={'flex'}
        justifyContent={'center'}
        flexBasis={1}
        flexGrow={1}
        flexShrink={0.5}
        minWidth={{ base: 385 }}
      >
        <Box width={'100%'} maxWidth={'100%'} overflow={'visible'}>
          <HeaderText mb={4}>
            webpages made <br /> with hatch
          </HeaderText>
          <List spacing={6} ref={carouselWrapperRef}>
            {items.map((item, index) => (
              <CarouselListItem
                key={item.text}
                isActive={activeIndex === index}
                onPointerUp={handlePointerEventSetIndex(index)}
                onPointerOver={handlePointerEventSetIndex(index)}
              >
                {item.text}
              </CarouselListItem>
            ))}
          </List>
          <CTA />
        </Box>
      </Box>
      <Box flexGrow={1} flexBasis={1} flexShrink={0.5}>
        <AspectRatio ratio={514 / 750} maxHeight={'750'} maxWidth={'514'}>
          <Box
            id={'image-holder'}
            height={'100%'}
            width={'100%'}
            borderRadius={'32px'}
            overflow={'hidden'}
            alignSelf={'stretch'}
            position={'relative'}
            margin={'auto'}
          >
            {items.map((item, index) => (
              <CarouselAsset
                isActive={index === activeIndex}
                isPreviouslyActive={previouslyActiveIndex === index}
                key={item.text}
                {...item}
              />
            ))}
          </Box>
        </AspectRatio>
      </Box>
    </>
  );
};

function CarouselListItem({ isActive, ...props }: { isActive?: boolean } & ListItemProps) {
  return (
    <ListItem
      fontSize={{ base: 20, md: 28, xl: 32 }}
      color={isActive ? 'white' : 'blue.200'}
      fontWeight={isActive ? '800' : '500'}
      transition={'all 0.2s ease-in-out'}
      className={`${isActive ? 'carousel-list-item-active' : ''}`}
      cursor={'pointer'}
      whiteSpace={'nowrap'}
      {...props}
    />
  );
}
