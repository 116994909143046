import { ModalHeader, useDisclosure } from '@chakra-ui/react';
import {
  Box,
  BoxProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  isSmallScreen,
  useIsSmallScreen,
} from '@playful/design_system';
import {
  SimpleCarousel,
  SimpleCarouselBody,
  SimpleCarouselItem,
  SimpleCarouselNextPrev,
} from '@playful/frontend/components/SimpleCarousel';
import { useLocalStorageState } from '@playful/frontend/hooks/handyHooks';
import { ProjectInfo } from '@playful/runtime';
import { customEvent } from '@playful/telemetry';
import React, { useCallback, useState } from 'react';

import { Tutorial } from '../types';
import { CalloutCard } from './CalloutCard';
import { DefaultCard } from './DefaultCard';
import { TutorialProject } from './TutorialProject';
import { TutorialVideo } from './TutorialVideo';

export function TutorialsCarousel({
  tutorials,
  infos,
}: {
  tutorials: Tutorial[];
  infos: Record<string, ProjectInfo>;
}) {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [videoUrl, setVideoUrl] = useState<string>();
  const openVideoModal = useCallback(
    (videoUrl: string) => {
      setVideoUrl(videoUrl);
      onOpen();
    },
    [onOpen, setVideoUrl],
  );
  const [hiddenCalloutKeys, setHiddenCalloutKeys] = useLocalStorageState<string[]>(
    [],
    'tutorialHiddenCalloutKeys',
  );

  const filteredTutorials = tutorials.filter(
    (tutorial) => !hiddenCalloutKeys.includes(tutorial._key),
  );

  const handleSelection = useCallback(
    (tutorial: Tutorial) => {
      if (tutorial._type === 'tutorialVideo') openVideoModal(tutorial.video.mediaUrl);

      customEvent('tutorial-view', {
        title: tutorial.title,
        ...(tutorial._type === 'tutorialProject' && { projectId: tutorial.project.id }),
      });
    },
    [openVideoModal],
  );

  const isSmScreen = useIsSmallScreen(true);

  // quick n dirty inline render prop-like function to conditionally render our various card types to avoid
  // prop drilling (having to pass in all potentially required props to a single component, something that
  // weighs down our other Card/Thumbnail components).
  const TutorialCard = useCallback(
    ({ tutorial, ...boxProps }: BoxProps & { tutorial: Tutorial }) => {
      if (tutorial.callout && !isSmScreen)
        return (
          <CalloutCard
            tutorial={tutorial}
            onSelection={handleSelection}
            onCollapse={({ _key }) => {
              setHiddenCalloutKeys((prevKeys) => [...prevKeys, _key]);
              customEvent('tutorial-close-callout', { title: tutorial.title });
            }}
            {...boxProps}
          />
        );

      return <DefaultCard tutorial={tutorial} onSelection={handleSelection} {...boxProps} />;
    },
    [handleSelection, setHiddenCalloutKeys, isSmScreen],
  );

  return (
    <>
      <SimpleCarousel w='100%' totalCount={filteredTutorials.length}>
        <SimpleCarouselNextPrev
          aria-label='previous item'
          direction='prev'
          left={{ base: 4, md: 10 }}
        />
        <SimpleCarouselBody>
          {filteredTutorials.map((tutorial, i) => (
            <SimpleCarouselItem
              key={i}
              idx={i}
              // we want the carousel to scroll beyond the padding (full bleed), so we add the padding conditionally
              // to the start and end slides
              pl={i === 0 ? { base: 6, md: 12 } : undefined}
              pr={i === filteredTutorials.length - 1 ? { base: 6, md: 12 } : undefined}
            >
              <TutorialCard tutorial={tutorial}>
                {tutorial._type === 'tutorialProject' ? (
                  <TutorialProject
                    info={infos[tutorial.project.id]}
                    previewUrl={tutorial.project.previewVideoUrl}
                  />
                ) : (
                  <TutorialVideo src={tutorial.video.mediaUrl} poster={tutorial.video.poster} />
                )}
              </TutorialCard>
            </SimpleCarouselItem>
          ))}
        </SimpleCarouselBody>
        <SimpleCarouselNextPrev
          aria-label='next item'
          direction='next'
          right={{ base: 4, md: 10 }}
        />
      </SimpleCarousel>
      {videoUrl && (
        <Modal
          size={isSmallScreen() ? 'almost-full-mobile' : 'almost-full'}
          isOpen={isOpen}
          onClose={onClose}
          aria-labelledby='form-dialog-title'
          scrollBehavior='inside'
        >
          <ModalOverlay />
          <ModalContent bg='gray.900' color='white'>
            <ModalHeader display='flex' justifyContent='flex-end' px={6} pt={6} pb={3.5}>
              <ModalCloseButton
                top={0}
                right={0}
                zIndex='modal'
                position='relative'
                bg='gray.700'
                color='white'
                _focus={{ bg: 'gray.500' }}
                _active={{ bg: 'gray.500' }}
                _hover={{ bg: 'gray.500' }}
              />
            </ModalHeader>
            <ModalBody px={10} pb={10} pt={0} display='flex'>
              <Box
                as='video'
                flexGrow={1}
                controls
                maxH='100%'
                w='100%'
                bg='black'
                autoPlay
                src={videoUrl}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}
