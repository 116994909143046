import { Box } from '@playful/design_system';
import React from 'react';

export function Footer() {
  return (
    <Box height={64} backgroundColor={'gray.900'} display={'flex'} justifyContent={'center'}>
      <Box
        display={'flex'}
        justifyContent={'space-evenly'}
        alignItems={'center'}
        flexDirection={'column'}
      >
        <Box
          display={'flex'}
          flexDirection={{ base: 'column', md: 'row' }}
          gap={{ base: 2, md: 8 }}
          color={'white'}
          fontSize={'lg'}
          textDecoration={'underline'}
          fontWeight={'bold'}
          alignItems={'center'}
        >
          <a target={'_blank'} rel='noreferrer' aria-label={'About Hatch'} href={'/about-us'}>
            About Us
          </a>
          <a target={'_blank'} rel='noreferrer' aria-label={'Hatch Careers'} href={'/work-here/'}>
            Work Here
          </a>
          <a href={'/terms-and-conditions/'} aria-label={'Hatch Terms of Use'}>
            Terms of Use
          </a>
          <a href={'/privacy-policy/'} aria-label={'Hatch Privacy Policy'}>
            Privacy Policy
          </a>
        </Box>
      </Box>
    </Box>
  );
}
