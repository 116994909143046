import { AspectRatio, AspectRatioProps } from '@chakra-ui/react';
import {
  ThumbnailImage,
  ThumbnailImageProps,
  ThumbnailVideo,
  ThumbnailVideoProps,
} from '@playful/design_system';
import React from 'react';

export function ThumbImage(props: ThumbnailImageProps) {
  return <ThumbnailImage borderRadius='sm' {...props} />;
}

export function ThumbVideo(props: ThumbnailVideoProps) {
  return <ThumbnailVideo borderRadius='sm' {...props} />;
}

export function ThumbFrame(props: AspectRatioProps) {
  return <AspectRatio ratio={4 / 3} w='300px' h='226px' cursor='pointer' {...props} />;
}
