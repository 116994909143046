import {
  Box,
  BoxProps,
  Heading,
  PlusIcon,
  Text,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Button,
  IconButton,
  CloseIcon,
  TrashIcon,
} from '@playful/design_system';
import { CTAButton } from '@playful/frontend/components/CallToAction/CTAButton';
import { useUserProjects } from '@playful/frontend/hooks/useProjects';
import { ROUTE_NEW_PROJECT } from '@playful/frontend/routes';
import { useUserContext } from '@playful/frontend/user/UserContext';
import { FEATURE_INTERNAL_TOOLS } from '@playful/api';
import React, { useEffect, useState } from 'react';

import { FileSystemEntry, createProjectFromDirectory } from '../explorer/importProject';
import { buildProjectEditRoute } from '../paths';
import { MyProjectsGrid } from './components/MyProjectsGrid';
import { useRouter } from '../hooks/useRouter';
import { useDisclosure } from '@chakra-ui/react';
import { ProjectInfo } from '@playful/runtime';

export function MyProjects(props: BoxProps) {
  const { push } = useRouter();
  const { user, hasFlag } = useUserContext();
  const { projectInfos, newProjectInfo, deleteProject } = useUserProjects(user.id, undefined, {
    revalidateOnFocus: false,
    revalidateOnMount: false,
    revalidateIfStale: false,
  });
  const [dropFiles, setDropFiles] = useState<FileList>();
  const [dropFileEntries, setDropFileEntries] = useState<FileSystemEntry[]>();
  const [selectedInfos, setSelectedInfos] = useState<Set<string>>(new Set());
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCmdPressed, setIsCmdPressed] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Meta' || event.key === 'Control') {
        setIsCmdPressed(true);
      }

      if (event.key === 'Escape') {
        setIsCmdPressed(false);
        setSelectedInfos(new Set());
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === 'Meta' || event.key === 'Control') {
        setIsCmdPressed(false);
      }
    };

    const handleMouseLeave = () => setIsCmdPressed(false);

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);
    document.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
      document.addEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  const isBulkModeEnabled = isCmdPressed || !!selectedInfos.size;

  // Employees can drag drop exported project directories onto "my projects" to import them.
  useEffect(() => {
    if (dropFileEntries) {
      const fileEntry = dropFileEntries[0];
      if (fileEntry && fileEntry.isDirectory) {
        // Create a project containing all the files in the directory then open it
        // in the Workbench. Special smarts for info.json and project.json files.
        createProjectFromDirectory(fileEntry, newProjectInfo, user).then((info) =>
          push(buildProjectEditRoute(info)),
        );
      } else {
        // TODO: Make more robust to handle an arbitrary list of files, not just
        // directories.
      }
      setDropFiles(undefined);
      setDropFileEntries(undefined);
    }
  }, [dropFiles, dropFileEntries, newProjectInfo, user, push]);

  const enableProjectImport = hasFlag(FEATURE_INTERNAL_TOOLS);
  return (
    <Box
      {...props}
      onDragOver={enableProjectImport ? onDragOver : undefined}
      onDrop={enableProjectImport ? onDrop : undefined}
    >
      <Heading as='h1' size='lg' noOfLines={1} display='flex' gap={2}>
        my projects
        {isBulkModeEnabled && !!selectedInfos.size && (
          <Box
            bg='gray.50'
            padding={1}
            border={'1px'}
            borderColor={'gray.100'}
            borderRadius='full'
            display='flex'
            gap={3}
            alignItems='center'
            boxShadow='sm'
            fontSize='sm'
          >
            <IconButton
              variant='ghost'
              size='xs'
              rounded='full'
              aria-label='leave bulk mode'
              onClick={() => setSelectedInfos(new Set())}
              icon={<CloseIcon />}
            />
            <span>{selectedInfos.size} selected</span>
            <IconButton
              variant='ghost'
              size='xs'
              rounded='full'
              aria-label='delete projects'
              onClick={onOpen}
              icon={<TrashIcon />}
            />
          </Box>
        )}
      </Heading>
      {projectInfos.length ? (
        <MyProjectsGrid
          onBulkSelection={(info: ProjectInfo) => {
            if (selectedInfos.has(info.id)) {
              selectedInfos.delete(info.id);
              setSelectedInfos((prev) => new Set(prev));
            } else {
              setSelectedInfos((prev) => new Set(prev).add(info.id));
            }
          }}
          isBulkModeEnabled={isBulkModeEnabled}
          selectedInfos={selectedInfos}
        />
      ) : (
        <Box
          mt={8}
          height={180}
          width={'100%'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          border={'1px solid var(--play-colors-gray-100)'}
          borderRadius={'md'}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={4}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Text color={'gray.900'}>You don&apos;t have any projects yet :(</Text>
            <CTAButton
              leftIcon={<PlusIcon />}
              onClick={() =>
                push({ query: { newProject: true } }, ROUTE_NEW_PROJECT, { shallow: true })
              }
            >
              create one
            </CTAButton>
          </Box>
        </Box>
      )}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent maxWidth={'460px'} mx={4}>
          <ModalHeader pt={6}>Delete these projects forever?</ModalHeader>
          <ModalBody fontWeight={'normal'} fontSize={'1rem'}>
            If you delete these projects they will be gone forever. If any of these projects have
            been published, any links to them will break. They will be removed from the gallery and
            your profile.
          </ModalBody>
          <ModalFooter gap={4} pb={6}>
            <Button variant={'outline'} w={'100%'} onClick={onClose} borderColor={'gray.200'}>
              cancel
            </Button>
            <CTAButton
              colorScheme='red'
              w={'100%'}
              onClick={() => {
                selectedInfos.forEach((id) => deleteProject(id));
                setSelectedInfos(new Set());
                onClose();
              }}
            >
              delete them
            </CTAButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );

  function onDragOver(event: React.DragEvent): void {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
  }

  function onDrop(event: React.DragEvent): void {
    // Keep URL drops from navigating away.
    event.preventDefault();

    if (event.dataTransfer.files.length > 0) {
      setDropFiles(event.dataTransfer.files);
      // We must extract the file entries immediately because dataTransfer.items.webkitGetAsEntry()
      // contents are cleared after this event is processed.
      const items = [];
      for (let i = 0; i < event.dataTransfer.items.length; i++) {
        const item = event.dataTransfer.items[i];
        const getAsEntry = (item as any).getAsEntry || item.webkitGetAsEntry;

        // IE does not support getAsEntry. But do we really support IE?
        // https://caniuse.com/mdn-api_datatransferitem_webkitgetasentry
        if (!getAsEntry) {
          alert('This ancient browser does not support importing projects via drag/drop.');
          return;
        }
        items.push(getAsEntry.call(item));
      }
      setDropFileEntries(items);
    }
  }
}
