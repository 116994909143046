import {
  Box,
  BoxProps,
  Button,
  CloseIcon,
  Heading,
  IconButton,
  PlayActionIcon,
  Text,
  isSmallScreen,
} from '@playful/design_system';
import { useUserContext } from '@playful/frontend/user/UserContext';
import React from 'react';

import { Tutorial } from '../types';
import { ThumbFrame } from './Thumbnails';

export function CalloutCard({
  tutorial,
  onSelection,
  onCollapse,
  children,
  ...boxProps
}: BoxProps & {
  tutorial: Tutorial;
  onSelection: (tutorial: Tutorial) => void;
  onCollapse: (tutorial: Tutorial) => void;
}) {
  const { isLoggedInUser } = useUserContext();

  return (
    <Box
      display='flex'
      bg='purple.700'
      p={4}
      borderRadius='md'
      gap={6}
      pos='relative'
      {...boxProps}
    >
      {isLoggedInUser && !isSmallScreen() && (
        <IconButton
          variant='ghost'
          aria-label='close'
          icon={<CloseIcon />}
          minW={0}
          h='24px'
          color='white'
          pos='absolute'
          top={3}
          right={3}
          onClick={() => onCollapse(tutorial)}
        />
      )}
      <ThumbFrame onClick={() => onSelection(tutorial)}>{children}</ThumbFrame>
      <Box w='304px' display='flex' flexDir='column' justifyContent='center' gap={3}>
        <Heading as='h3' size='md'>
          {tutorial.title}
        </Heading>
        <Text>{tutorial.content}</Text>
        <Box>
          <Button
            colorScheme='yellow'
            color='gray.700'
            size='md'
            leftIcon={<PlayActionIcon />}
            onClick={() => onSelection(tutorial)}
          >
            watch now
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
