import React, { PropsWithChildren } from 'react';

type FallbackComponentProps = PropsWithChildren<{
  isLoading: boolean;
  fallback: React.ReactNode;
}>;

/**
 * Simple placeholder component to show a fallback while loading (e.g. skeletons)
 */
export function FallbackComponent({ isLoading, fallback, children }: FallbackComponentProps) {
  if (isLoading) return <>{fallback}</>;

  return <>{children}</>;
}
