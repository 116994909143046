import { BoxProps, TextProps } from '@chakra-ui/react';
import { Box, Text } from '@playful/design_system';
import React from 'react';

export function PageSection({
  children,
  background,
  backgroundColor,
  id,
  ...boxProps
}: { children?: React.ReactNode } & BoxProps) {
  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      background={background}
      backgroundColor={backgroundColor}
      id={id}
    >
      <Box
        maxWidth={{ base: 'calc(min(1280px, 100%))' }}
        width={{ base: 'calc(min(1280px, 100%))' }}
        px={{ base: 4, md: 10, xl: 4, '2xl': 0 }}
        id={`${id}-inner`}
        {...boxProps}
      >
        {children}
      </Box>
    </Box>
  );
}

export function HeaderText({
  children,
  ...TextProps
}: {
  children: React.ReactNode;
} & TextProps) {
  return (
    <Text
      variant={'sectionHeader'}
      fontSize={{ base: 32, md: 44, xl: 60 }}
      fontWeight={'extrabold'}
      color={'white'}
      {...TextProps}
    >
      {children}
    </Text>
  );
}
