import { Box, BoxProps, ThumbnailCardLabel } from '@playful/design_system';
import React from 'react';

import { Tutorial } from '../types';
import { ThumbFrame } from './Thumbnails';

export function DefaultCard({
  onSelection,
  tutorial,
  children,
  ...boxProps
}: BoxProps & {
  tutorial: Tutorial;
  onSelection: (tutorial: Tutorial) => void;
}) {
  return (
    <>
      <ThumbFrame onClick={() => onSelection(tutorial)} {...boxProps}>
        {children}
      </ThumbFrame>
      <Box
        display='flex'
        justifyContent='space-between'
        mt={2}
        gap={1}
        alignItems='flex-start'
        wordBreak='break-all'
      >
        <ThumbnailCardLabel color='white'>{tutorial.title}</ThumbnailCardLabel>
      </Box>
    </>
  );
}
