import { Box, Heading, Text } from '@playful/design_system';
import React, { PropsWithChildren } from 'react';

type SectionHeadingProps = {
  subtitle?: string;
  subtitleRight?: React.ReactNode;
};

export const SectionHeading = ({
  children,
  subtitle,
  subtitleRight,
}: PropsWithChildren<SectionHeadingProps>) => {
  return (
    <Box display={'flex'} flexDirection={'column'} gap={1}>
      <Heading as='h1' fontSize={{ base: '2xl', md: '3xl' }} color={'gray.700'} userSelect={'text'}>
        {children}
      </Heading>
      {subtitle && (
        <Box
          display={'flex'}
          justifyContent={{ base: 'space-between', md: 'space-between' }}
          flexDirection={{ base: 'column', md: 'row' }}
          gap={1}
        >
          <Text fontSize={{ base: 'sm', md: 'md' }} color={'gray.700'} userSelect={'text'}>
            {subtitle}
          </Text>
          {subtitleRight}
        </Box>
      )}
    </Box>
  );
};
