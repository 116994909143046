import { Box, ButtonLink } from '@playful/design_system';
import { triggerCustomEvent } from '@playful/telemetry';
import React from 'react';

import { HeaderText } from './homePageComponents';

export default function FeatureGrid() {
  return (
    <>
      <HeaderText textAlign={'center'} color={'gray.900'} fontSize={{ base: 32, md: 44, xl: 60 }}>
        make your webpage
        <br />
        an experience
      </HeaderText>
      <Box width='100%' display={'flex'} justifyContent={'center'}>
        <ButtonLink
          href={'/new-project'}
          bg={'yellow.500'}
          mt={{ base: 6 }}
          px={4}
          size={'lg'}
          color={'gray.950'}
          variant={'outline'}
          _hover={{ bg: 'gray.950', color: 'white', textDecoration: 'none' }}
          onClick={triggerCustomEvent('homepage-cta-click', { link: 'startcreating' })}
        >
          start creating
        </ButtonLink>
      </Box>
    </>
  );
}
