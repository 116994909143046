import { ProjectList } from '@playful/frontend/components/ProjectGrids';
import { UserCard } from '@playful/frontend/explorer/ProjectThumbnailCard';
import { useUserProjects } from '@playful/frontend/hooks/useProjects';
import { useUserContext } from '@playful/frontend/user/UserContext';
import { FEATURE_PROJECT_TAGS } from '@playful/api';
import type { ProjectInfo } from '@playful/runtime';
import React, { useEffect, useMemo, useState } from 'react';
import { getDatabase, off, onValue, ref } from 'firebase/database';

import {
  TaggedProjectsHeader,
  TaggedProjectsTabs,
  TemplateCategory,
} from '../../components/TemplatePickers';

const categories = [
  { title: 'all', slug: '*', filter: (_: ProjectInfo) => true },
  {
    title: 'unpublished',
    slug: 'unpublished' as const,
    filter: (project: ProjectInfo) => !project.published,
  },
  {
    title: 'published',
    slug: 'published' as const,
    filter: (project: ProjectInfo) => !!project.published,
  },
  {
    title: 'remixable',
    slug: 'remixable' as const,
    filter: (project: ProjectInfo) => project.permissions.allowRemixing,
  },
  {
    title: 'shared with community',
    slug: 'shared' as const,
    filter: (project: ProjectInfo) => project.permissions.showInGallery,
  },
];

type CategoryValue = (typeof categories)[number]['slug'] | string;

export function MyProjectsGrid({
  onBulkSelection,
  isBulkModeEnabled,
  selectedInfos,
}: {
  isBulkModeEnabled: boolean;
  selectedInfos: Set<string>;
  onBulkSelection: (info: ProjectInfo) => void;
}) {
  const { user, hasFlag } = useUserContext();
  const { projectInfos, mutate: mutateUserProjects } = useUserProjects(user.id, undefined, {
    revalidateOnFocus: false,
    revalidateOnMount: false,
    revalidateIfStale: false,
  });
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (!user.id) return;

    const userPushRef = ref(getDatabase(), `userPush/${user.id}/user_projects`);

    const onProjectsValue = () => mutateUserProjects?.(); // trigger a revalidation

    onValue(userPushRef, onProjectsValue);

    return () => {
      off(userPushRef, 'value', onProjectsValue);
    };
  }, [user.id, mutateUserProjects]);

  // these are provided in no particular order, so sort by most recent
  const sortedInfosByRecent = useMemo(
    () =>
      projectInfos
        .slice()
        .sort((a, b) => new Date(b.modified).getTime() - new Date(a.modified).getTime()),
    [projectInfos],
  );

  const allCategories = useMemo(() => {
    const all = categories.slice();

    // Add all the tags as categories, if the feature is enabled
    if (hasFlag(FEATURE_PROJECT_TAGS)) {
      projectInfos.forEach((project) => {
        if (project.tags) {
          Object.keys(project.tags).forEach((tag: string | null) => {
            if (!tag) return;
            // Remove the order from the tag
            const tagName = tag.toLowerCase().split(':')[0];
            if (!all.find((cat) => cat.slug === tagName)) {
              all.push({
                title: tagName,
                slug: tagName,
                filter: (project: ProjectInfo) => {
                  return (
                    !!project.tags &&
                    !!Object.keys(project.tags).find((t) => {
                      // Check if the tag includes the `tag` in 'tag:order'; e.g. `gallery_featured:21`
                      return t.toLowerCase().includes(tagName);
                    })
                  );
                },
              });
            }
          });
        }
      });
    }

    const sorted = all
      // remove categoies that are already in the standard list
      .filter((cat) => !categories.find((c) => c.slug === cat.slug))
      // sort the non-standard categories alphabetically
      .sort((a, b) => a.title.localeCompare(b.title));

    // Prepend the standard categories to the sorted list
    return [...categories, ...sorted];
  }, [hasFlag, projectInfos]);

  const projectsInCategories = useMemo(
    () =>
      allCategories.reduce(
        (acc, cat) => {
          acc[cat.slug] = sortedInfosByRecent.filter(cat.filter);
          return acc;
        },
        {} as Record<CategoryValue, ProjectInfo[]>,
      ),

    [allCategories, sortedInfosByRecent],
  );

  const categoriesWithProjects = useMemo(
    () => allCategories.filter((cat) => !!projectsInCategories[cat.slug].length),
    [allCategories, projectsInCategories],
  );

  // the tabIndex should never exceed the number of categories with projects.
  // this can happen if a user has the last tab selected and removes the last project
  // from it, causing it to be removed. This prevents a bad state from occurring before
  // the useEffect below can catch it.
  const activeTabIdx = Math.min(categoriesWithProjects.length - 1, tabIndex);
  const activeCategory = categoriesWithProjects[activeTabIdx];

  useEffect(() => {
    if (tabIndex !== activeTabIdx) return setTabIndex(activeTabIdx);

    // catch-all. this probably won't happen, but just in case.
    if (!activeCategory) setTabIndex(0);
  }, [activeTabIdx, tabIndex, activeCategory]);

  // no projects in any category, so don't show anything
  if (!projectInfos.length) return null;

  return (
    <TaggedProjectsTabs onTabChange={setTabIndex} tabIndex={activeTabIdx}>
      <TaggedProjectsHeader
        isLoading={!projectsInCategories}
        categories={categoriesWithProjects as any as TemplateCategory[]}
        wrap={hasFlag(FEATURE_PROJECT_TAGS)}
      />
      <ProjectList projectInfos={projectsInCategories[activeCategory.slug]}>
        {(info) => (
          <UserCard
            onBulkSelection={() => onBulkSelection(info)}
            isBulkSelected={selectedInfos.has(info.id)}
            isBulkModeEnabled={isBulkModeEnabled}
            fallbackData={info}
            projectId={info.id}
          />
        )}
      </ProjectList>
    </TaggedProjectsTabs>
  );
}
