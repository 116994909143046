import { Box, BoxProps, SimpleGrid } from '@playful/design_system';
import { ROUTE_GALLERY } from '@playful/frontend/routes';
import groq from 'groq';
import React from 'react';
import useSWR from 'swr';

import { CommunityCard } from '../../explorer/ProjectThumbnailCard';
import { sanityFetch } from '../../sanity';
import { SectionHeading } from '../SectionHeading';
import type { GridProps } from './types';
import { ViewAll } from './ViewAll';

const featuredProjectsQuery = groq`
*[_id == "galleryPage"][0].categories[slug==null][0].projects[]->{
  'id': project.id
}`;

type FeaturedProjectsQuery = {
  id: string;
}[];

async function fetchFeaturedProjects() {
  return sanityFetch<FeaturedProjectsQuery>(featuredProjectsQuery);
}

export function CommunityProjectsGrid({ fallbackData, ...boxProps }: BoxProps & GridProps) {
  const { data: featuredProjects } = useSWR('featuredProjects', fetchFeaturedProjects);

  return (
    <Box {...boxProps}>
      <SectionHeading subtitleRight={<ViewAll href={ROUTE_GALLERY} />}>
        find inspiration in our community gallery
      </SectionHeading>
      <SimpleGrid
        py={4}
        columns={{ base: 1, xs: 1, sm: 1, md: 2, lg: 3, xl: 4, '2xl': 5 }}
        gap={{ base: 4, xs: 4, sm: 4, md: 6, lg: 6, xl: 6 }}
      >
        {featuredProjects?.map((info) => (
          <CommunityCard objectFit='cover' projectId={info.id} key={info.id} />
        ))}
      </SimpleGrid>
    </Box>
  );
}
