import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { IconButton, IconButtonProps } from '@chakra-ui/react';
import { DOMAttributes, PropGetter } from '@chakra-ui/react-types';
import { Box, BoxProps, isSmallScreen } from '@playful/design_system';
import { createGenericContext } from '@playful/utils';
import { AnimationControls, useAnimation } from 'framer-motion';
import React, { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

const [useCarouselCtx, CarouselProvider] = createGenericContext<{
  getItemProps({ idx, ...props }: DOMAttributes & { idx: number }): {
    'data-idx': number;
  };
  getNextProps: PropGetter;
  getPrevProps: PropGetter;
  getItemListProps: PropGetter;
  observer: IntersectionObserver | undefined;
  onNext(): void;
  onPrev(): void;
  bodyRef: RefObject<HTMLElement>;
  controls: AnimationControls;
  addHandler(idx: number, handler: (scrollTo: (ref: RefObject<HTMLElement>) => void) => void): void;
}>({ displayName: 'SimpleCarousel' });

export function useSimpleCarouselItem({
  idx,
  observer,
}: {
  idx: number;
  observer: IntersectionObserver | undefined;
}) {
  const { addHandler } = useCarouselCtx();

  const elRef = useRef<HTMLElement>(null);
  const el = elRef.current;

  useEffect(() => {
    return addHandler(idx, (scrollTo) => {
      scrollTo(elRef);
    });
  }, [addHandler, idx, elRef]);

  useEffect(() => {
    if (el) observer?.observe(el);

    return () => {
      if (el) observer?.unobserve(el);
    };
  }, [observer, el]);

  return { ref: elRef };
}

// all values from 0.01 to 1
const threshold = [...Array.from({ length: 100 }, (_, i) => i / 100).filter(Boolean), 1];

export function useSimpleCarousel({
  totalCount,
  hideControls = false,
}: {
  totalCount: number;
  hideControls?: boolean;
}) {
  const [visibleIndexes, setVisibleIndexes] = useState<number[]>([]);
  const [observer, setObserver] = useState<IntersectionObserver>();
  const isScrolling = useRef(false);
  const bodyRef = useRef<HTMLElement>(null);
  const handlers = useRef<
    Record<string, (scrollTo: (ref: RefObject<HTMLElement>) => void) => void>
  >({});
  const controls = useAnimation();
  const scrollStop = useDebouncedCallback(() => (isScrolling.current = false), 100);
  const [isSmScreen, setSmScreen] = useState(false);

  useEffect(() => {
    setSmScreen(isSmallScreen());
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        setVisibleIndexes((prev) => {
          const updatedItems = new Set(prev);

          for (const entry of entries) {
            const idx = Number((entry.target as HTMLElement).dataset.idx);

            // sometimes, even if it's fully visible, it will be 0.99 and not 1. so, we leave room for
            // a tiny margin of error.
            if (entry.intersectionRatio >= 0.99 && entry.isIntersecting) {
              updatedItems.add(idx);
            } else {
              updatedItems.delete(idx);
            }
          }

          const allIntersections = entries.filter((entry) => entry.isIntersecting);

          // if no items are totally visible, but there is at least 1 visible item, we should to find the most visible
          // item and use that as our anchor point.
          if (!updatedItems.size && allIntersections.length) {
            const highestIntersecting = allIntersections.reduce((acc, curr) =>
              acc.intersectionRatio > curr.intersectionRatio ? acc : curr,
            );

            const intersectingIdx = Number((highestIntersecting.target as HTMLElement).dataset.idx);

            updatedItems.add(intersectingIdx);
          }

          return Array.from(updatedItems);
        });
      },
      {
        // the strategy is to use all thresholds, with filtering logic to determine which items are visible.
        // we need this as in some circumstances and screen sizes, there may be scenarios where even one slide
        // is too big to completely fit, but we should still show arrows
        threshold,
        // checks only for visibility on the x axis (we don't care about y for our slideshow)
        rootMargin: '100% 0% 100% 0%',
      },
    );
    setObserver(observer);

    return () => observer.disconnect();
  }, []);

  // detect when the user is scrolling
  useEffect(() => {
    const scrollEl = bodyRef.current;

    if (!scrollEl) return;

    const handleScroll = () => {
      isScrolling.current = true;
      scrollStop();
    };

    scrollEl.addEventListener('scroll', handleScroll);

    return () => {
      scrollEl.removeEventListener('scroll', handleScroll);
    };
  }, [isScrolling, scrollStop]);

  const addHandler = useCallback(
    (idx: number, handler: (scrollTo: (ref: RefObject<HTMLElement>) => void) => void) => {
      handlers.current[idx] = handler;

      return () => delete handlers.current[idx];
    },
    [],
  );

  const scrollRefIntoView = (ref: RefObject<HTMLElement>) => {
    if (!ref.current || !bodyRef.current) return;

    const elementRect = ref.current.getBoundingClientRect();

    // if user is mid-scroll and tries to navigate, override overflow to try and stop inertia
    if (isScrolling.current) {
      bodyRef.current.style.overflowX = 'hidden';
      bodyRef.current.style.overflowX = 'auto';
    }

    // we use scroll over scrollIntoView because scrollIntoView won't run if the user is in the middle of scrolling,
    // and is inconsistent in what it determines as "in view".
    bodyRef.current.scroll({
      left:
        elementRect.left +
        bodyRef.current.scrollLeft -
        bodyRef.current.clientWidth / 2 +
        elementRect.width / 2,
      behavior: 'smooth',
    });
  };

  function onNext() {
    const idx = Math.min(Math.max(...visibleIndexes) + 1, totalCount - 1);
    handlers.current[idx]?.(scrollRefIntoView);
  }

  function onPrev() {
    const idx = Math.max(0, Math.min(...visibleIndexes) - 1);
    handlers.current[idx]?.(scrollRefIntoView);
  }

  const getItemProps = ({ idx, ...props }: DOMAttributes & { idx: number }) => {
    return {
      scrollSnapAlign: 'center',
      'data-idx': idx,
      ...props,
    };
  };

  const getNextProps: PropGetter = (props) => {
    return {
      onClick: onNext,
      hidden: hideControls || isSmScreen || visibleIndexes.includes(totalCount - 1),
      ...props,
    };
  };

  const getPrevProps: PropGetter = (props) => {
    return {
      onClick: onPrev,
      hidden: hideControls || isSmScreen || !visibleIndexes.length || visibleIndexes.includes(0),
      ...props,
    };
  };

  const getItemListProps: PropGetter = (props) => {
    return {
      overflowX: 'auto',
      scrollSnapType: 'x mandatory',
      display: 'grid',
      gridGap: 4,
      listStyleType: 'none',
      alignItems: 'center',
      scrollBehavior: 'smooth',
      sx: {
        '&::-webkit-scrollbar': { display: 'none' },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        grid: 'auto / auto-flow max-content',
        ...(props?.style ?? {}),
      },
      ...props,
    };
  };

  return {
    controls,
    addHandler,
    getNextProps,
    getItemListProps,
    getPrevProps,
    onNext,
    onPrev,
    getItemProps,
    observer,
    bodyRef,
  };
}

export function SimpleCarousel({
  children,
  totalCount,
  hideControls,
  ...props
}: {
  children: React.ReactNode;
  totalCount: number;
  hideControls?: boolean;
} & BoxProps) {
  const ctx = useSimpleCarousel({ totalCount, hideControls });

  return (
    <CarouselProvider value={ctx}>
      <Box pos='relative' {...props}>
        {children}
      </Box>
    </CarouselProvider>
  );
}

export function SimpleCarouselNextPrev({
  direction,
  ...props
}: { direction: 'prev' | 'next' } & IconButtonProps) {
  const { getNextProps, getPrevProps } = useCarouselCtx();
  const dirProps = (
    direction === 'prev' ? getPrevProps(props) : getNextProps(props)
  ) as IconButtonProps;

  return (
    <IconButton
      aria-hidden='true'
      size='lg'
      icon={direction === 'prev' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      pos='absolute'
      top={'calc(50% - 24px)'}
      left={direction === 'prev' ? 0 : undefined}
      right={direction === 'next' ? 0 : undefined}
      zIndex={'sticky'}
      shadow={'md'}
      _focus={{ shadow: 'md' }}
      colorScheme='white'
      variant='solid'
      {...dirProps}
    />
  );
}

export function SimpleCarouselBody(props: BoxProps) {
  const { getItemListProps, bodyRef, controls } = useCarouselCtx();

  return <Box ref={bodyRef} controls={controls} {...getItemListProps(props)} />;
}

export function SimpleCarouselItem(props: BoxProps & { idx: number }) {
  const { getItemProps, observer } = useCarouselCtx();
  const { ref } = useSimpleCarouselItem({
    idx: props.idx,
    observer,
  });

  return <Box ref={ref} {...getItemProps(props)} />;
}
