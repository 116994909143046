import { AspectRatio, BoxProps, forwardRef } from '@chakra-ui/react';
import { Box } from '@playful/design_system';
import { useGesture } from '@use-gesture/react';
import React, { useEffect, useRef, useState } from 'react';

import { CTA, CarouselAsset, CarouselItem } from './CTACarousel';
import { HeaderText } from './homePageComponents';

export const CTACarouselMobile = ({ items }: { items: CarouselItem[] }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const carouselWrapperRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const carouselWrapper = carouselWrapperRef.current;
    const stop = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };

    carouselWrapper?.addEventListener('touchmove', stop, { passive: false });

    return () => {
      carouselWrapper?.removeEventListener('touchmove', stop);
    };
  }, []);

  return (
    <>
      <Box
        id={'cta-list'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        maxWidth={'100%'}
      >
        <Box flexGrow={1} flexBasis={1} flexShrink={0} width={'100%'} maxWidth={324}>
          <AspectRatio ratio={612 / 892}>
            <Box
              id={'image-holder'}
              borderRadius={'32px'}
              overflow={'hidden'}
              boxShadow={
                '0px 15px 80px 0px rgba(0, 0, 0, 0.20), 0px 5px 10px 0px rgba(0, 0, 0, 0.20), 0px 0px 0px 1px rgba(0, 0, 0, 0.10);'
              }
              position={'relative'}
              width={'100%'}
            >
              {items.map((item, index) => (
                <CarouselAsset isActive={index === activeIndex} key={item.text} {...item} />
              ))}
            </Box>
          </AspectRatio>
        </Box>
        <HeaderText my={6} textAlign='center'>
          webpages made with hatch
        </HeaderText>
        <Box width={'100vw'} overflowX={'hidden'} ref={carouselWrapperRef}>
          <ListCarousel items={items} activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
        </Box>
        <CTA />
      </Box>
    </>
  );
};

function ListCarousel(props: {
  items: CarouselItem[];
  activeIndex: number;
  setActiveIndex: (index: number) => void;
}) {
  const trackRef = useRef<HTMLDivElement>();
  const activeItemRef = useRef<HTMLDivElement>();
  const { items, activeIndex, setActiveIndex } = props;
  const [xOffset, setXOffset] = useState(0);
  const timeoutRef = useRef<number>();

  const previousIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
  const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;

  const nextItem = items[nextIndex];
  const previousItem = items[previousIndex];
  const activeItem = items[activeIndex];

  const handleClickNext = () => {
    setActiveIndex(nextIndex);
  };

  const handleClickPrevious = () => {
    setActiveIndex(previousIndex);
  };

  const handleAutoAdvance = () => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(() => {
      handleClickNext();
    }, 5000);
  };

  useEffect(() => {
    handleAutoAdvance();

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [activeIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  const bind = useGesture(
    {
      onDrag: (state) => {
        const {
          swipe: [swipeX],
          delta: [dx],
          memo,
        } = state;
        if (!trackRef.current || !activeItemRef.current) return;

        let { movedX } = memo || { movedX: 0 };

        if (swipeX !== 0) {
          if (swipeX === -1) {
            setActiveIndex(nextIndex);
          } else {
            setActiveIndex(previousIndex);
          }
          return;
        }
        const trackRect = trackRef.current.getBoundingClientRect();

        const trackQuarter = trackRect.width / 4;

        // if we've moved more than 25% the width of the screen then we should move to the next item
        if (movedX > trackQuarter) {
          setActiveIndex(previousIndex);
          movedX = 0;
        } else if (movedX < -trackQuarter) {
          setActiveIndex(nextIndex);
          movedX = 0;
        } else {
          setXOffset((x) => x + dx);
        }
        return {
          movedX: movedX + dx,
        };
      },
      onDragStart: () => {
        clearTimeout(timeoutRef.current);
      },
      onDragEnd: () => {
        handleAutoAdvance();
      },
    },
    { drag: { axis: 'x' } },
  );

  useEffect(() => {
    if (!trackRef.current || !activeItemRef.current) return;
    const trackRect = trackRef.current.getBoundingClientRect();
    const activeItemRect = activeItemRef.current.getBoundingClientRect();

    const trackCenter = trackRect.left + trackRect.width / 2;
    const activeItemCenter = activeItemRect.left + activeItemRect.width / 2;
    const diff = trackCenter - activeItemCenter;
    setXOffset(diff + 8);
  }, [activeIndex]);

  return (
    <Box display={'flex'} gap={4} ref={trackRef} transform={`translateX(${xOffset}px)`} {...bind()}>
      <ListCarouselBoxItem key={previousItem.text} onClick={handleClickPrevious}>
        {previousItem.text}
      </ListCarouselBoxItem>
      <ListCarouselBoxItem key={activeItem.text} isActive ref={activeItemRef}>
        {activeItem.text}
      </ListCarouselBoxItem>
      <ListCarouselBoxItem key={nextItem.text} onClick={handleClickNext}>
        {nextItem.text}
      </ListCarouselBoxItem>
    </Box>
  );
}
const ListCarouselBoxItem = forwardRef(
  ({ isActive, ...props }: { isActive?: boolean } & BoxProps, ref) => {
    return (
      <Box
        fontSize={{ base: 20, md: 28, xl: 32 }}
        color={isActive ? 'white' : 'blue.200'}
        fontWeight={isActive ? '800' : '500'}
        transition={'all 0.2s ease-in-out'}
        className={`${isActive ? 'carousel-list-item-active' : ''}`}
        cursor={'pointer'}
        whiteSpace={'nowrap'}
        display={'flex'}
        ref={ref}
        {...props}
      />
    );
  },
);
