import { ThumbnailVideoProps } from '@playful/design_system';
import { Image } from '@sanity/types';
import React from 'react';

import { urlFor } from '../../../sanity';
import { ThumbVideo } from './Thumbnails';

export function TutorialVideo({
  poster,
  ...props
}: Omit<ThumbnailVideoProps, 'poster'> & { poster?: Image }) {
  const posterUrl = poster && urlFor(poster).width(300).url();

  return <ThumbVideo poster={posterUrl} {...props} />;
}
