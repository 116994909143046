import type { User } from '@playful/api';
import { ProjectInfo, deserialize, serialize } from '@playful/runtime';
import { UnsavedProjectInfo, createProjectInfo } from '@playful/workbench/api/projects';
import { PROJECT_JSON, PROJECT_STATE, Resource } from '@playful/workbench/project/resources';

// TODO: official types
export interface FileSystemEntry {
  name: string;
  fullPath: string;
  isFile: boolean;
  isDirectory: boolean;
  file(callback: (file: File) => void): void;
  createReader(): FileSystemDirectoryReader;
}

interface FileSystemDirectoryReader {
  readEntries(resolve: (entries: FileSystemEntry[]) => void, reject: any): void;
}

// TODO: progress indicator
export async function createProjectFromDirectory(
  dirEntry: FileSystemEntry,
  newProjectInfo: (user: User, title: string) => UnsavedProjectInfo,
  user: User,
): Promise<ProjectInfo> {
  const entries = await readAllDirectoryEntries(dirEntry.createReader());

  // If the directory has an info.json file read it and transfer relevant properties
  // to the new project.
  let title = dirEntry.name;
  let priorInfo;
  const priorInfoEntry = getFileEntry('info.json', entries);
  if (priorInfoEntry) {
    const { text } = await readFileEntryAsText(priorInfoEntry);
    priorInfo = JSON.parse(text) as ProjectInfo;
    if (priorInfo.title) {
      // Let the info title override the directory name but set it up
      // before calling newProjectInfo so it will apply its unique
      // project title smarts.
      title = priorInfo.title;
    }
  }

  const info = newProjectInfo(user, title);

  if (priorInfo) {
    if (priorInfo.template) {
      info.template = priorInfo.template;
    }
  }

  const res = await Resource.create(PROJECT_STATE);
  info.project = res.id;

  // If the directory has a project.json use it as the new project's state.
  const projectEntry = getFileEntry('project.json', entries);
  if (projectEntry) {
    const { text } = await readFileEntryAsText(projectEntry);
    const state = deserialize(text);
    const file = serialize(state);
    const buffer = new TextEncoder().encode(file);
    await res.uploadDataBuffer(PROJECT_JSON, buffer, 'application/json');
  }

  for (const entry of entries) {
    // We've already handled project info and state.
    const name = stripRootDir(entry.fullPath);
    if (name === 'info.json' || name === 'project.json') {
      continue;
    }
    const { buffer, mimeType } = await readFileEntryAsArrayBuffer(entry);
    await res.uploadDataBuffer(name, buffer, mimeType);
  }

  return await createProjectInfo(info);
}

// fullPaths look like e.g. "/root-dir/some-path/some-file.ext".
// Returns e.g. "some-path/some-file.ext"
function stripRootDir(fullPath: string): string {
  return fullPath.slice(fullPath.indexOf('/', 1) + 1);
}

function getFileEntry(name: string, entries: FileSystemEntry[]): FileSystemEntry | undefined {
  return entries.find((entry) => stripRootDir(entry.fullPath) === name);
}

async function readFileEntryAsArrayBuffer(
  entry: FileSystemEntry,
): Promise<{ buffer: ArrayBuffer; mimeType: string }> {
  return new Promise((resolve, reject) => {
    entry.file(async (file) => {
      const buffer = await file.arrayBuffer();
      resolve({ buffer, mimeType: file.type });
    });
  });
}

async function readFileEntryAsText(
  entry: FileSystemEntry,
): Promise<{ text: string; mimeType: string }> {
  return new Promise((resolve, reject) => {
    entry.file(async (file) => {
      const text = await file.text();
      resolve({ text, mimeType: file.type });
    });
  });
}

// Get all the entries (files or sub-directories) in a directory
// by calling readEntries until it returns empty array
async function readAllDirectoryEntries(
  directoryReader: FileSystemDirectoryReader,
): Promise<FileSystemEntry[]> {
  const fileEntries = [];
  let entries;
  do {
    entries = await readEntries(directoryReader);
    for (const entry of entries) {
      // Some things we just don't want to import.
      if (['.git', 'node_modules', '.DS_Store'].includes(entry.name)) {
        continue;
      }

      // Recurse on directories
      if (entry.isDirectory) {
        const subEntries = await readAllDirectoryEntries(entry.createReader());
        fileEntries.push(...subEntries);
      } else {
        fileEntries.push(entry);
      }
    }
  } while (entries.length > 0);
  return fileEntries;
}

// Wrap readEntries in a promise to make working with readEntries easier
// readEntries will return only some of the entries in a directory
// e.g. Chrome returns at most 100 entries at a time
async function readEntries(directoryReader: FileSystemDirectoryReader): Promise<FileSystemEntry[]> {
  return new Promise<FileSystemEntry[]>((resolve, reject) => {
    directoryReader.readEntries(resolve, reject);
  });
}
