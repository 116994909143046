import { RouteLink } from '@playful/design_system';
import { useProjectThumb } from '@playful/frontend/explorer/ProjectThumbnailCard';
import { buildUserRoute } from '@playful/frontend/paths';
import { ProjectInfo } from '@playful/runtime';
import React from 'react';

import { ThumbImage, ThumbVideo } from './Thumbnails';
import { useRouter } from '../../../hooks/useRouter';

export function TutorialProject({
  previewUrl,
  info,
  onClick,
}: {
  previewUrl?: string;
  info?: ProjectInfo;
  onClick?: () => void;
}) {
  const { mediaUrl, poster, type } = useProjectThumb({
    projectId: info?.id,
    videoUrl: previewUrl,
  });
  const { asPath } = useRouter();

  if (!info) return null;

  return (
    <RouteLink
      asPath={info && buildUserRoute(info.ownerName, info.slug)}
      href={{
        query: {
          from: asPath,
          userName: info.ownerName,
          slug: info.slug,
          playerModal: true,
        },
      }}
      shallow
      onClick={onClick}
    >
      {type === 'video' ? (
        <ThumbVideo src={mediaUrl} poster={poster} title={info.title} />
      ) : (
        <ThumbImage src={mediaUrl} />
      )}
    </RouteLink>
  );
}
