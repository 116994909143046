import { AspectRatio, BoxProps } from '@chakra-ui/react';
import {
  Box,
  DesignToolsIcon,
  GlassIcon,
  Icon as HatchIcon,
  Link,
  LinkIcon,
  MagicPenIcon,
  Text,
} from '@playful/design_system';
import { formatEventString, triggerCustomEvent } from '@playful/telemetry';
import React from 'react';

import dragAndDrop from './assets/01-drag-drop.mp4';
import codeless from './assets/02-codeless.mp4';
import aiCode from './assets/03-ai-code.mp4';
import bringTogether from './assets/04-bring-together.mp4';
import { HeaderText } from './homePageComponents';
import { VideoPreview } from './VideoPreview';

export function VideoTimeline() {
  return (
    <>
      <Box display={'flex'} justifyContent={'left'} overflow='hidden'>
        <Box
          id={'video-list'}
          color={'white'}
          ml={{ base: 5, md: 8 }}
          pt={{ base: 20 }}
          display={'flex'}
          flexDirection={'column'}
        >
          <VideoItem
            title={
              <>
                drag + drop <br />
                design
              </>
            }
            Icon={DesignToolsIcon}
            color={'#FFD401'}
            video={dragAndDrop}
            linkText={'start designing'}
            subtitle={
              'Start from a template or a blank canvas. Import your own graphics or pick from our library. Stack, tilt, and drag anywhere without the limits of a grid.'
            }
          />
          <VideoItem
            title={
              <>
                interactivity
                <br />
                without code
              </>
            }
            Icon={GlassIcon}
            color={'#A368F4'}
            video={codeless}
            linkText={'get moving'}
            subtitle={
              'Add one-click interactive effects to create animation, motion, and responsive physics without technical expertise.'
            }
          />
          <VideoItem
            title={
              <>
                customizable
                <br />
                code
              </>
            }
            Icon={MagicPenIcon}
            color={'pink.500'}
            video={aiCode}
            linkText={'build something new'}
            subtitle={
              'Use our AI coder to describe the interactions and animations you want. Hatch will write and run the code in your project. Ask for changes or edit the code yourself.'
            }
          />
          <VideoItem
            title={'publish & share with one easy link'}
            Icon={LinkIcon}
            color={'#B4E053'}
          />
        </Box>
      </Box>
      <Box mt={{ base: 8, md: 14 }} borderRadius={'16px'} overflow={'hidden'}>
        <AspectRatio ratio={1200 / 776}>
          <VideoPreview src={bringTogether} />
        </AspectRatio>
      </Box>
    </>
  );
}

export function VideoItem(props: {
  video?: string;
  title: React.ReactNode;
  subtitle?: string;
  linkText?: string;
  color: string;
  Icon: typeof HatchIcon;
  boxProps?: BoxProps;
}) {
  const { video, subtitle, boxProps, color, linkText, title, Icon } = props;

  return (
    <Box display={'flex'} alignItems={'stretch'}>
      <Box
        mx={0}
        ml={{ base: '10px', lg: '7px' }}
        width={{ base: '3px', lg: '3px' }}
        minWidth={{ base: '3px', lg: '3px' }}
        maxWidth={{ base: '3px', lg: '3px' }}
        mr={{ base: 9, md: 12 }}
        height={'calc(100% + var(--play-space-14))'}
        backgroundClip={'content-box'}
        background={color}
      >
        <TimelineIcon Icon={Icon} color={color} />
      </Box>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-around'}
        gap={10}
        flexDirection={{ base: 'column', lg: 'row' }}
        alignSelf={video ? undefined : 'baseline'}
        pos={'relative'}
        mb={video ? { base: '80px', md: '112px' } : 0}
        {...boxProps}
      >
        <Box
          pos={'relative'}
          display={'flex'}
          flexDirection={'column'}
          gap={6}
          flexBasis={'50%'}
          flexGrow={1}
          alignSelf={'baseline'}
          transform={{ base: 'translateY(7px)', md: 'none', xl: 'translateY(-3px)' }}
        >
          <HeaderText pr={{ base: '48px', md: 0 }} minWidth={{ base: 0, xl: '584' }}>
            {title}
          </HeaderText>
          {subtitle && (
            <>
              <Text
                fontSize={{ base: 16, md: 20, lg: 28 }}
                fontWeight={'400'}
                lineHeight={'150%'}
                pr={{ base: '48px', md: 0 }}
              >
                {subtitle}
              </Text>
              <Box mt={0}>
                <Link
                  color={color}
                  href={'/new-project'}
                  fontSize={{ base: 16, md: 20, lg: 24 }}
                  onClick={triggerCustomEvent('homepage-cta-click', {
                    link: formatEventString(linkText ?? ''),
                  })}
                >
                  {linkText}
                </Link>
              </Box>
            </>
          )}
        </Box>
        {video && (
          <Box
            alignSelf={'stretch'}
            width={{ base: 'calc(100vw - 64px)', md: '100%' }}
            transform={{ base: 'translate(-56px)', md: 'none' }}
            flexBasis={'50%'}
          >
            <Box id={'video-holder'} borderRadius={'16px'} overflow={'hidden'}>
              <AspectRatio ratio={560 / 540} maxWidth={560} maxHeight={540}>
                <VideoPreview src={video} />
              </AspectRatio>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

function TimelineIcon({
  children,
  color,
  Icon,
  ...props
}: BoxProps & { Icon: typeof HatchIcon; color: string }) {
  return (
    <Box
      height={'64px'}
      width={'44px'}
      bg={'gray.950'}
      display={'flex'}
      py={4}
      transform={'translateX(-13px)'}
      {...props}
    >
      <Icon h={8} width={8} color={color} />
    </Box>
  );
}
