import { RouteLink, RouteLinkProps } from '@playful/design_system';
import React from 'react';

export function ViewAll(props: RouteLinkProps) {
  return (
    <RouteLink lineHeight={'shorter'} size={'md'} fontWeight={'bold'} {...props}>
      view all
    </RouteLink>
  );
}
