import { Box, BoxProps, SimpleGridProps, useMergeRefs } from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Options as InViewOptions, useInView } from 'react-cool-inview';

export type VirtualItemRow = {
  id?: number;
  items: any[];
};
type VirtualCardGridProps<T> = SimpleGridProps & {
  items: T[];
  columnCount?: number;
  renderItem: (item: T) => React.ReactNode;
  preloadCount?: number;
};
export function VirtualList<T>({
  items,
  columnCount = 1,
  children,
  renderItem,
  preloadCount = 0,
  ...gridProps
}: VirtualCardGridProps<T>) {
  const [rowHeight, setRowHeight] = useState<number>(0);
  const firstRowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const entry = entries[0];
      setRowHeight(entry.contentRect.height);
    });
    if (firstRowRef.current) {
      resizeObserver.observe(firstRowRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const [firstRow, rows] = useMemo(() => {
    const r: VirtualItemRow[] = [];
    for (let i = 0; i < items.length; i += columnCount) {
      const chunk = items.slice(i, i + columnCount);
      r.push({ id: i, items: chunk });
    }
    return [r[0], r.slice(1)];
  }, [items, columnCount]);

  return (
    <>
      <VirtualListRow alwaysRender ref={firstRowRef} {...gridProps}>
        {firstRow?.items && firstRow?.items.length > 0
          ? firstRow?.items.map((item, i) => renderItem(item))
          : null}
      </VirtualListRow>

      {preloadCount > 0 &&
        rows.slice(0, preloadCount).map((row, index) => (
          <VirtualListRow
            alwaysRender
            key={`${row.id}-row-start`}
            rowHeight={rowHeight}
            {...gridProps}
          >
            {row.items.map((item, i) => renderItem(item))}
          </VirtualListRow>
        ))}

      {rowHeight > 0 &&
        rows.slice(preloadCount).map((row, index) => (
          <VirtualListRow key={`${row.id}-row-start`} rowHeight={rowHeight} {...gridProps}>
            {row.items.map((item, i) => renderItem(item))}
          </VirtualListRow>
        ))}
    </>
  );
}

export const VirtualListRow = forwardRef(
  (
    {
      children,
      rowHeight,
      inViewOptions,
      alwaysRender,
      ...rest
    }: {
      children: React.ReactNode;
      rowHeight?: number;
      inViewOptions?: InViewOptions<HTMLDivElement>;
      alwaysRender?: boolean;
    } & BoxProps,
    ref,
  ) => {
    const { observe, inView } = useInView(inViewOptions);
    const mergedRefs = useMergeRefs(ref, observe);

    return (
      <Box ref={mergedRefs} minHeight={rowHeight} {...rest}>
        {alwaysRender || inView ? children : null}
      </Box>
    );
  },
);
