import { Box, useBreakpointValue } from '@chakra-ui/react';
import { SimpleGrid, SimpleGridProps } from '@playful/design_system';
import type { ProjectInfo } from '@playful/runtime';
import React, { Fragment } from 'react';

import { VirtualList } from '../VirtualList';

export type ProjectListProps = Pick<SimpleGridProps, 'columns'> & {
  children: (info: ProjectInfo) => React.ReactNode;
  projectInfos: ProjectInfo[];
  isFeatured?: boolean;
  preloadCount?: number;
  columnCount?: number;
};

export const ProjectList = ({
  projectInfos,
  preloadCount = 0,
  columns,
  columnCount,
  children,
  isFeatured,
}: ProjectListProps) => {
  const defaultColumns = { base: 2, xs: 2, sm: 2, md: 3, lg: 5, xl: 6 };
  const defaultColumnCount = useBreakpointValue<number>(defaultColumns);
  const virtualColumns = columns ?? defaultColumns;
  const virtualColumnCount = columnCount ?? defaultColumnCount ?? 5;
  const featuredColumns = { base: 1, xs: 1, sm: 1, md: 2, lg: 3, xl: 4, '2xl': 5 };
  const featuredColumnCount = useBreakpointValue<number>(featuredColumns);

  return (
    <Box py={4}>
      <VirtualList
        as={SimpleGrid}
        pb={6}
        columns={isFeatured ? featuredColumns : virtualColumns}
        columnCount={isFeatured ? featuredColumnCount : virtualColumnCount}
        gap={{ base: 4, xs: 4, sm: 4, md: 6, lg: 6, xl: 6 }}
        items={projectInfos}
        preloadCount={preloadCount}
        renderItem={(info) => (
          <Fragment key={info.id + '-' + info.version}>{children(info)}</Fragment>
        )}
      />
    </Box>
  );
};
