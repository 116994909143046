import { ButtonLink, ButtonLinkProps, useHatchBreakpoints } from '@playful/design_system';
import { triggerCustomEvent } from '@playful/telemetry';
import React from 'react';

import portfolio from './assets/01-portfolio.mp4';
import gravity from './assets/02-gravity.mp4';
import links from './assets/03-links-page.png';
import color from './assets/04-color.mp4';
import { CTACarouselDesktop } from './CTACarouselDesktop';
import { CTACarouselMobile } from './CTACarouselMobile';
import { VideoPreview } from './VideoPreview';

export type CarouselItem = {
  text: string;
  asset: string | any;
  type: 'video' | 'image';
};

const items: CarouselItem[] = [
  {
    text: 'an unforgettable portfolio',
    asset: portfolio,
    type: 'video',
  },
  {
    text: 'a business page with gravity',
    asset: gravity,
    type: 'video',
  },
  {
    text: 'a links page with soul',
    asset: links.src,
    type: 'image',
  },
  {
    text: 'a colorful gallery',
    asset: color,
    type: 'video',
  },
];

export function CTACarousel() {
  const { isLargeScreen } = useHatchBreakpoints();

  if (isLargeScreen) {
    return <CTACarouselDesktop items={items} />;
  }

  return <CTACarouselMobile items={items} />;
}

export function CarouselAsset(
  props: CarouselItem & { isActive: boolean; isPreviouslyActive?: boolean },
) {
  const { text, type, asset, isActive, isPreviouslyActive } = props;
  return (
    <>
      {type === 'video' && (
        <VideoPreview
          height={'100%'}
          style={{ objectFit: 'cover' }}
          src={asset}
          className={`carousel-asset ${
            isActive ? 'carousel-asset-active' : isPreviouslyActive ? 'carousel-asset-inactive' : ''
          }`}
        />
      )}
      {type === 'image' && (
        <img
          src={asset}
          alt={text}
          className={`carousel-asset ${
            isActive ? 'carousel-asset-active' : isPreviouslyActive ? 'carousel-asset-inactive' : ''
          }`}
        />
      )}
    </>
  );
}

export function CTA(props: ButtonLinkProps) {
  return (
    <ButtonLink
      href={'/new-project'}
      onClick={triggerCustomEvent('homepage-cta-click', { link: 'startyourwebpage' })}
      bg={'yellow.500'}
      mt={{ base: 6, md: 12 }}
      px={4}
      size={'lg'}
      color={'gray.700'}
      {...props}
    >
      start your webpage
    </ButtonLink>
  );
}
