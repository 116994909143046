import { Skeleton, SkeletonText, useBreakpointValue } from '@chakra-ui/react';
import { Box, BoxProps, SimpleGrid, ThumbnailPreview } from '@playful/design_system';
import React from 'react';

export function MyProjectsSkeleton(props: BoxProps) {
  const limit = useBreakpointValue({
    base: 4,
    xs: 4,
    sm: 4,
    md: 4,
    lg: 5,
    xl: 6,
  });

  return (
    <Box {...props}>
      <Skeleton h='36px' w='230px' mt='30px' mb='16px' />
      <Skeleton h='24px' w='100px' />
      <SimpleGrid
        py={4}
        columns={{ base: 2, xs: 2, sm: 2, md: 3, lg: 5, xl: 6 }}
        gap={{ base: 4, xs: 4, sm: 4, md: 6, lg: 6 }}
      >
        {new Array(limit).fill(0).map((_, i) => (
          <Box position='relative' flexGrow={1} key={i}>
            <ThumbnailPreview Thumbnail={<Skeleton />} />
            <SkeletonText mt={4} w='100px' noOfLines={1} />
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
}
